import React, { Suspense, lazy } from "react"
import { PropTypes as PT } from "prop-types"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import "./styles/index.scss"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#C50015"
    },
    label: {
      main: "#797777"
    }
  },
  typography: {
    fontFamily: ['"Open Sans"', "sans-serif"].join(","),
    h1: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "30px"
    }
  }
})

const ChangePassword = lazy(() => import("./Containers/Pages/ChangePassword/ChangePassword"))
const Clients = lazy(() => import("./Containers/Pages/Clients/Clients"))
const Drivers = lazy(() => import("./Containers/Pages/Drivers/Drivers"))
const CreateAccount = lazy(() => import("./Containers/Pages/CreateAccount/CreateAccount"))
const Deliveries = lazy(() => import("./Containers/Pages/Deliveries/Deliveries"))
const DriverView = lazy(() => import("./Containers/Pages/DriverView/DriverView"))
const Login = lazy(() => import("./Containers/Pages/Login/Login"))
const Orders = lazy(() => import("./Containers/Pages/Orders/Orders"))
const PasswordReminder = lazy(() => import("./Containers/Pages/PasswordReminder/PasswordReminder"))
const NewPassword = lazy(() => import("./Containers/Pages/NewPassword/NewPassword"))

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <PrivateRoute exact path="/">
              <Orders />
            </PrivateRoute>
            <PrivateRoute exact path="/order-history">
              <Orders showHistoryOrders />
            </PrivateRoute>
            <PrivateRoute exact path="/clients">
              <Clients />
            </PrivateRoute>
            <PrivateRoute exact path="/drivers">
              <Drivers />
            </PrivateRoute>
            <PrivateRoute exact path="/change-password">
              <ChangePassword />
            </PrivateRoute>
            <PrivateRoute exact path="/deliveries">
              <Deliveries />
            </PrivateRoute>
            <Route exact path="/create-account" component={CreateAccount} />
            <Route exact path="/driver/:routeId" component={DriverView} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password" component={PasswordReminder} />
            <Route path="/setup-new-password" component={NewPassword} />
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  )
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest} // eslint-disable-line
      render={({ location }) =>
        localStorage.getItem("token") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  children: PT.node.isRequired
}

export default App
